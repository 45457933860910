<template>
  <div class="flex">
    <pre>
    <h1>Partner Google Privacy Policy</h1>
    <p>
      Our application fully adheres to the Google API Services User Data Policy,
      ensuring compliance with its Limited Use requirements. For more
      information, please refer to the
      <a
        href="https://developers.google.com/terms/api-services-user-data-policy"
        >Google API Services User Data Policy</a
      >.
    </p>
  </pre>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default {
  name: 'GooglePrivacyPolicy',
  setup() {
    return {
      defineComponent,
    };
  },
};
</script>

<style lang="scss" scoped>
pre {
  padding: 40px;
  width: 100vw;
  height: 100%;
  word-break: break-word;
  white-space: pre-wrap;
}
</style>
